import React from 'react';
import InfoCardsBlock from "../../blocks/InfoCardsBlock.js";
import PageHeaderBlock from "../../blocks/PageHeaderBlock.js";
import SignUpBlock from "../../blocks/static/SignUpBlock.js";
import SEO from "../../components/seo.js";
import Layout from "../../layout/Layout.js";


import content from '../../i18n/lp/card-acquiring/ru.js';
import '../../styles/pages/lp/card-acquiring.scss';
import SignUpLegalEntityForm from '../../forms/instances/SignUpLegalEntityForm.js';

const BLOCK_CONFIG = {
    animation: "2",
    baseBorder: false,
  };

const CardAcquiringPage = ({ pathContext: { locale } }) => {
    const signUpForm = <SignUpLegalEntityForm textSubmit={ content.SignUpLegalEntityBlock.textSubmit } />
    return (
      <Layout locale={locale} isSimple footerLegalInfo={content.Footer.legal}>
        <SEO title={content.seo.title} description={content.seo.description} />
        <PageHeaderBlock
          locale={locale}
          title={content.pageHeaderBlock.title}
          text={content.pageHeaderBlock.text}
          signUpForm={signUpForm}
        />
        <InfoCardsBlock
          cardsTitle={content.infoCardsBlock.cardsTitle}
          cardsTitleColor="black"
          cardsLayout="rowString"
          config={BLOCK_CONFIG}
          cards={content.infoCardsBlock.cards}
        />
        <InfoCardsBlock
          cardsTitle={content.infoCardsBlockFeatures.cardsTitle}
          cardsTitleColor="black"
          cardsLayout="rowString"
          config={BLOCK_CONFIG}
          cards={content.infoCardsBlockFeatures.cards}
        />
        <InfoCardsBlock
          cardsTitle={content.infoCardsBlockSecond.cardsTitle}
          cardsTitleColor="grey"
          cardsLayout="column"
          cards={content.infoCardsBlockSecond.cards}
          config={BLOCK_CONFIG}
        />
        <InfoCardsBlock
          listTitle={content.infoCardsBlockThird.listTitle}
          listColor="black"
          list={content.infoCardsBlockThird.list}
          listGoTo={content.infoCardsBlockThird.listGoTo}
        />
        <SignUpBlock signUpForm={signUpForm} />
      </Layout>
    );
};

export default CardAcquiringPage;
