import * as PropTypes from "prop-types";
import React from "react";

import Block from "../Block";
import BlockSection from "../BlockSection";

const SignUpBlock = ({  signUpForm }) => {
    return (
      <Block
        className="Block_theme_form PaymentsBottomFOSsmallWithoutTitle"
        config={{
          baseBorder: true,
          animation: "3",
        }}
      >
        <div className="container">
          <BlockSection>
            <div className="Block-Form">
             {signUpForm}
            </div>
          </BlockSection>
        </div>
      </Block>
    );
};

SignUpBlock.propTypes = {
  signUpForm: PropTypes.instanceOf(React.Component),
};

export default SignUpBlock;
