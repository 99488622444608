import iconSimpleForBuyer from "../../../images/icons/styled/first/iconSimpleForBuyer.svg";
import iconLightning from "../../../images/icons/styled/first/Lightning.svg";
import iconShield from "../../../images/icons/styled/first/Shield.svg";
import iconSplitPayments from "../../../images/icons/styled/first/splitPayments.svg";
import iconCard from "../../../images/icons/styled/second/Card.svg";

export default {
  seo: {
    title: "Интернет-эквайринг для бизнеса | Банк 131",
    description:
      "Подключайте прием оплаты на сайте банковскими картами: Visa, MasterCard, «Мир». Ставка от 1,31% · Для любого типа бизнеса · Быстрое подключение · Поддержка 24/7",
  },
  pageHeaderBlock: {
    title: "Интернет-эквайринг от 1,31% в Банк 131",
    text: "Выгодные ставки для каждого типа бинеса и любых оборотов",
  },
  infoCardsBlock: {
    cardsTitle: "Используйте удобные платежные методы для вашего бизнеса",
    cards: [
      {
        title: "Банковские карты ",
        titleSwtyle: "small",
        icon: iconCard,
        theme: "withoutBackground",
        productCardContentClass: "Flex-column-left ProductCard-Content-Full",
        text:
          "Принимайте платежи банковскими картами VISA, Mastercard, «Мир» и получайте деньги на счет в любом банке",
      },
    ],
  },
  infoCardsBlockFeatures: {
    cards: [
      {
        title: "Просто для покупателя ",
        titleStyle: "small ProductCard-TitleOnlinebank",
        icon: iconSimpleForBuyer,
        theme: "withoutBackgroundRow",
        productCardContentClass: "Flex-column-left ProductCard-Content-Full",
        text: "",
        list: [
          "Оплата в один клик – без повторного ввода данных",
          "Полные и частичные возвраты",
          "Быстрая безопасная оплата на сайте и в приложениях",
        ],
      },
      {
        title: "Удобно для компании",
        titleStyle: "small ProductCard-TitleOnlinebank",
        icon: iconCard,
        theme: "withoutBackgroundRow",
        productCardContentClass: "Flex-column-left ProductCard-Content-Full",
        text: "",
        list: [
          "Холдирование и рекурренты",
          "Мультирасчеты для маркетплейсов и онлайн площадок",
          "Перечисление в рублях на банковские счета в России и за рубежом",
        ],
        listStyle: "greenList",
      },
    ],
  },
  infoCardsBlockSecond: {
    cardsTitle: "Вам понравится",
    cards: [
      {
        title: "Сплитование платежей",
        titleStyle: "small",
        text:
          "Удобное решение для маркетплейсов и сервисов, если нужно отправить денежные средства сразу на несколько счетов. Делите платежи между разными получателями: поставщиками и партнерами.",
        icon: iconSplitPayments,
        description: "",
        theme: "withoutBackground",
      },
      {
        title: "Забота о безопасности",
        titleStyle: "small",
        text:
          "Все запросы к API шифруются, а защита платежной формы обеспечивается в соответствии стандартам PCI-DSS Level 4.",
        icon: iconShield,
        description: "",
        theme: "withoutBackground",
      },
      {
        title: "Быстрая интеграция по API",
        titleStyle: "small",
        text:
          "Современное и удобное API, гибкие схемы интеграции, тестирование до начала работы с реальными данными. Если не хватит возможностей – пишите, обсудим.",
        icon: iconLightning,
        description: "",
        theme: "withoutBackground",
      },
    ],
  },
  infoCardsBlockThird: {
    listTitle: "Как подключить прием платежей",
    list: [
      `Отправить заявку через форму обратной связи ниже`,
      `Подписать договор`,
      "Интегрировать сервис на свой сайт или приложение",
    ],
  },
  SignUpLegalEntityBlock: {
    textSubmit: "Подключиться",
  },
  Footer:{
    legal:"АО «Банк 131». ИНН 1655505780, ОГРН 1241600056390, Базовая лицензия на осуществление банковских операций со средствами в рублях и иностранной валюте (с правом привлечения во вклады денежных средств физических лиц) и на осуществление банковских операций с драгоценными металлами №3538 от 29 ноября 2024 г.\n\АО «Банк 131» является участником системы страхования вкладов.\n\nРеклама. Настоящая информация не является публичной офертой АО «Банк 131», выражающей намерение АО «Банк 131» заключить договор на использование усулуг интернет-эквайринга любому обратившемуся к АО «Банк 131» лицу."
  }
};

