import { useLocation } from "@reach/router";
import axios from 'axios';
import { Field, Form, Formik } from "formik";
import * as PropTypes from "prop-types";
import React from 'react';
import Interpolate from "react-interpolate-component";
import { useInView } from 'react-intersection-observer';
import Modal from "react-modal";
import ym from 'react-yandex-metrika';
import * as yup from 'yup';

import Button from "../../components/Button";
import CircleWithImage from "../../components/CircleWithImage";
import useLanguage from "../../hooks/useLanguage";
import iconCloseMobile from "../../images/icons/buttons/close-mobile.svg";
import iconClose from "../../images/icons/buttons/close.svg";
import iconError from "../../images/icons/form/error.svg";
import iconSuccess from "../../images/icons/form/success.svg";

import '../../styles/form/forms/callBackForm.scss';
import { createInternalLink } from '../../utils/createInternalLink';

import enMessages from '../../i18n/signUpForm/en.json';
import ruMessages from '../../i18n/signUpForm/ru.json';

const messages = {
  en: enMessages,
  ru: ruMessages,
};

const FORM_FIELD = {
  companyName: "",
  companyIdentificationNumber: "",
  confirmation: false,
  contactName: "",
  contactSurname: "",
  contactPatronymic: "",
  email: "",
  phone: "",
};
const FORM_SCREEN_DEFAULT = 'companyForm';

// TODO: форма для лендинга, который заменен новым лендингом, уточнить можно ли ее выпилить
const SignUpLegalEntityForm = ({ textSubmit, canBeFixed = "false", }) => {
  Modal.setAppElement("#___gatsby");

  const [language] = useLanguage();

  const { href, state } = useLocation();

  const signUpFosFormOpened = state?.signUpFosFormOpened || false;

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const [modalIsOpen, setIsOpen] = React.useState(signUpFosFormOpened);

  const [fosData, setCompanyData] = React.useState({
      screen: FORM_SCREEN_DEFAULT,
  });

  const [initialValues, setInitialValues] = React.useState(FORM_FIELD);


  const resetFormState = React.useCallback(
    () => {
      setInitialValues(FORM_FIELD);
    },
    [
      setInitialValues,
    ],
  );

  const handleSuccessButtonClick = React.useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen, setCompanyData,resetFormState]);

  const submitFOSData = (formData) => {
    setCompanyData({screen: 'process'});

    const sentData = new FormData();

    Object.keys(formData).forEach(key => {
      if (formData[key]) {
        sentData.append(key, formData[key]);
      }
    });

    sentData.append("source", href); 

    axios({
      method: 'post',
      url: '/scripts/application/createLeadCardAcquiring',
      headers: { 'content-type': 'multipart/form-data' },
      data: sentData,
    })
      .then((response) => {
        if (response.data.status === 'success') {
          setCompanyData({screen: 'success'});
          ym('reachGoal', 'fos_v3_send', { params: { url: href } });
        } else {
          setCompanyData({screen: 'error'});
        }
      })
      .catch(() => {
        setCompanyData({screen: 'error'});
      });
  };

  const validationSchema = yup.object({
    companyName: yup
      .string(messages[language].form_validation_incorrect_company_or_individual_entrepreneur)
      .min(2, messages[language].form_validation_incorrect_company_or_individual_entrepreneur)
      .required(messages[language].form_validation_company_or_individual_entrepreneur),
    companyIdentificationNumber: yup
      .string(messages[language].form_validation_incorrect_company_identification_number)
      .matches(/^(\d{10,12})$/, messages[language].form_validation_incorrect_company_identification_number)
      .required(messages[language].form_validation_company_identification_number)
      .optional(),
    contactName: yup
      .string(messages[language].form_validation_incorrect_name)
      .min(2, messages[language].form_validation_incorrect_name)
      .required(messages[language].form_validation_name),
    contactPatronymic: yup
      .string(messages[language].form_validation_incorrect_patronymic)
      .min(2, messages[language].form_validation_incorrect_patronymic),
    contactSurname: yup
      .string(messages[language].form_validation_incorrect_surname)
      .min(2, messages[language].form_validation_incorrect_surname)
      .required(messages[language].form_validation_surname),
    email: yup
      .string(messages[language].form_validaton_incorrect_mail)
      .email(messages[language].form_validaton_incorrect_mail)
      .required(messages[language].form_validation_mail),
    phone: yup
      .string(messages[language].form_validation_phone)
      .matches(/^((\+7|8)+([0-9]){10})$/, messages[language].form_validation_phone_correct)
      .required(messages[language].form_validation_phone),
  });

  const ScreenCompanyForm = () => (
    <Formik
      initialStatus="default"
      initialValues={initialValues}
      onSubmit={(values) => submitFOSData(values)}
      validationSchema={validationSchema}
    >
      {({ errors, values, touched }) => (
        <div>
          <Form className="FieldFormBase" >
            <Field
              className="callBackForm-inputText"
              name="companyName"
              placeholder={`${messages[language].userCompanyName}*`}
              type="text"
            />

            {errors.companyName && touched.companyName
              ? <div className="callBackForm-ValidationError">{errors.companyName}</div>
              : null}

            <Field
              className="callBackForm-inputText"
              maxLength={12}
              name="companyIdentificationNumber"
              placeholder={messages[language].userCompanyTaxIdentificationNumber}
              type="text"
            />

            {errors.companyIdentificationNumber && touched.companyIdentificationNumber
              ? <div className="callBackForm-ValidationError">{errors.companyIdentificationNumber}</div>
              : null}

            <Field
              className="callBackForm-inputText"
              name="contactSurname"
              placeholder={`${messages[language].userSurname}*`}
              type="text"
            />

            {errors.contactSurname && touched.contactSurname
              ? <div className="callBackForm-ValidationError">{errors.contactSurname}</div>
              : null}

            <Field
              className="callBackForm-inputText"
              name="contactName"
              placeholder={`${messages[language].userName}*`}
              type="text"
            />

            {errors.contactName && touched.contactName
              ? <div className="callBackForm-ValidationError">{errors.contactName}</div>
              : null}

            {language === 'ru' && <>
              <Field
                className="callBackForm-inputText"
                name="contactPatronymic"
                placeholder={messages[language].userPatronymic}
                type="text"
              />

              {errors.contactPatronymic && touched.contactPatronymic
                ? <div className="callBackForm-ValidationError">{errors.contactPatronymic}</div>
                : null}
            </>}

            <Field
              className="callBackForm-inputText"
              name="email"
              placeholder={`${messages[language].userMail}*`}
              type="text"
            />

            {errors.email && touched.email
              ? <div className="callBackForm-ValidationError">{errors.email}</div>
              : null}

            <Field
              type="text"
              placeholder={`${messages[language].companyphone}*`}
              name="phone"
              className="callBackForm-inputText"
              maxLength={12}
            />

            {errors.phone && touched.phone
              ? <div className="callBackForm-ValidationError">{errors.phone}</div>
              : null}


            <div className="callBackForm-agreement formBlockAgreementFos">
              <label>
                <Field type="checkbox" name="confirmation"/>

                  <Interpolate
                    with={{
                      linkToConsentProcessingPD: (
                        <a
                          href={createInternalLink(language, '/consent-processing-pd/')}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {messages[language].confirmationConsentProcessingPD}
                        </a>
                      ),
                      linkToPrivacyPolicy: (
                        <a
                          href={createInternalLink(language, '/dengiru/privacy-policy/')}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {messages[language].confirmationPrivacyPolicy}
                        </a>
                      ),
                    }}
                  >
                    {messages[language].confirmationLabel}
                  </Interpolate>
              </label>
            </div>

            <Button
              disabled={!values.confirmation}
              type="submit"
            >
              {messages[language].submit}
            </Button>
          </Form>
        </div>
      )}
    </Formik>
  );

  const ScreenSuccess = () => (
    <div className="callBackForm-BlockMessage">
      <CircleWithImage color="second" size="medium">
        <img src={iconSuccess} alt="icon" />
      </CircleWithImage>

      <div class="callBackForm-BlockMessage-text">
        {messages[language].form_result_success}
      </div>

      <div class="callBackForm-BlockMessage-button">
        <Button
          click={handleSuccessButtonClick}
          type="submit"
        >
          {messages[language].form_result_fos_success_button}
        </Button>
      </div>
    </div>
  );

  const ScreenError = () => (
    <div className="callBackForm-BlockMessage">
      <CircleWithImage color="first" size="medium">
        <img src={iconError} alt="icon" />
      </CircleWithImage>

      <div class="callBackForm-BlockMessage-text">
        {messages[language].form_result_error}
      </div>

      <div class="callBackForm-BlockMessage-desc">
        {messages[language].form_result_error_desc}
      </div>

      <div class="callBackForm-BlockMessage-button">
        <Button
          type="submit"
          click={handleSuccessButtonClick}
        >
          {messages[language].form_result_error_button}
        </Button>
      </div>
    </div>
  );


  const ScreenProcess = () => (
    <div className="callBackForm-BlockMessage callBackForm-BlockMessageProcess">
      <div class="callBackForm-BlockMessage-text">
        {messages[language].form_result_process}
      </div>
    </div>
  );


  const onOpen = React.useCallback(() => {
    setCompanyData({ screen: FORM_SCREEN_DEFAULT });
    resetFormState();
    setIsOpen(true);
  }, [resetFormState, setIsOpen]);

  const onClose = React.useCallback(() => {
    setIsOpen(false);
    resetFormState();
  }, [resetFormState, setIsOpen])

  return (
    <div ref={ref}>
      <div className={`button-fos-wrapper ${canBeFixed === "true" ? "button-fos-wrapper-canfixed" : ""} ${inView === false ? "fixedBtn": ""}`}>
          <Button click={onOpen}>
            {textSubmit}
          </Button>
      </div>

      <Modal
        className="callBackForm"
        closeTimeoutMS={100}
        isOpen={modalIsOpen}
        onRequestClose={onClose}
        overlayClassName="callBackForm-Overlay"
        portalClassName="FOS-callBackForm"
      >
        {fosData.screen === 'companyForm' && <ScreenCompanyForm />}
        {fosData.screen === 'success' && <ScreenSuccess />}
        {fosData.screen === 'error' && <ScreenError />}
        {fosData.screen === 'process' && <ScreenProcess />}

        <a onClick={onClose}>
          <img src={iconClose} className="callBackForm-iconClose" />
          <img src={iconCloseMobile} className="callBackForm-iconCloseMobile" />
        </a>
      </Modal>
    </div>
  );
};

SignUpLegalEntityForm.propTypes = {
  textSubmit: PropTypes.string,
};

export default SignUpLegalEntityForm;
